import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Box from 'components/Box';
import Heading from 'components/Heading';
import Page from 'components/Page';
import PageWrap from 'components/PageWrap';
import Text from 'components/Text';

import { fadeIn } from 'utils/transitions';

class NotFoundPage extends Component {
  state = {
    hasMounted: false,
  };

  componentDidMount() {
    this.setState({ hasMounted: true });
    fadeIn(this.animeContainer.childNodes);
  }

  render() {
    const { hasMounted } = this.state;
    const { data, location } = this.props;
    const metadata = data.allContentfulMetadata.edges[0].node;

    const pageProps = {
      location,
      image: metadata.defaultImage ? metadata.defaultImage.file.url : undefined,
      description: metadata.description,
      title: metadata.title,
      social: {
        twitter: metadata.twitterHandle,
        instagram: metadata.instagramUsername,
        facebook: metadata.facebookPage,
        messengerLink: metadata.messengerLink,
        feeldLink: metadata.feeldLink,
      },
    };

    const innerRef = node => {
      this.animeContainer = node;
    };

    return (
      <Page {...pageProps}>
        <PageWrap maxWidth={42} space={3}>
          <Box
            pb={5}
            innerRef={innerRef}
            maxWidth={480}
            opacity={hasMounted ? 1 : 0}
          >
            <Box mb={1}>
              <Heading tag={1} fontSize={4}>
                Page not found!
              </Heading>
            </Box>
            <Text>This page does not exist</Text>
          </Box>
        </PageWrap>
      </Page>
    );
  }
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query NotFoundQuery {
    allContentfulMetadata {
      edges {
        node {
          title
          description
          twitterHandle
          facebookPage
          instagramUsername
          messengerLink
          feeldLink
          defaultImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
